@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}
html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--bgColor);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
body .rtl {
    position: relative !important;
    font-family: var(--arRegular);
    font-style: normal;
    font-size: 15px;
}

img{
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
    
}
p{
    font-size: 15px;
    font-family: var(--regular);
    // padding-bottom: 15px;
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}


.textGrey{
    color: var(--textGrey);
}
.white{
    color: var(--white);
}
.themeColor{
    color: var(--themeColor);
}
.themeBgGradient{
        background: var(--themeBgGradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.themeTextGradient{
        background: var(--themeTextGradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.TextGradientFont{
    font-family: var(--extralight) !important;
}

.bg-textGrey{
    background: var(--textGrey);
}
.bg-white{
    background: var(--white);
}
.bg-themeColor{
    background: var(--themeColor);
}
.bg-themeBgGradient{
    background: var(--themeBgGradient);
}
.bg-themeTextGradient{
    background: var(--themeTextGradient);
}
.common-space{
    padding: 70px 0;
}

.btn-gradient>button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    width:145px;
    padding:6px 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    border:none;
    outline: 1px solid var(--themeColor);
    color: var(--themeColor);
    background : transparent;
    overflow: hidden;
    &::before, &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -100%;
        left: 0;
        background: var(--themeTextGradient);
        z-index: -1;
        transition: all 400ms ease-in;
    }
    svg{
        [stroke]{
            stroke: var(--themeColor) ;
        }
        [fill]{
            fill: var(--themeColor);
        }
    }
    &:hover{
        color: var(--white);
        outline:transparent;
        svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
        &::before,&::after{
            top: 0;
        }
        &::before {
            opacity: 0.3;
        }
        &::after {
            transition-delay: 0.3s;
        }
    }
}
.btn-gradient-bg>button {
    background: var(--themeTextGradient);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    width:145px;
    padding:6px 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    border:none;
    outline: none;
    color: var(--white);
    overflow: hidden;
    &::before, &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -100%;
        left: 0;
        background: var(--themeColor);
        z-index: -1;
        transition: all 400ms ease-in;
    }
    svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
    &:hover{
        color: var(--white);
        outline:1px solid var(--themeColor);
        background: none;
        svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
        &::before,&::after{
            top: 0;
        }
        &::before {
            opacity: 0.3;
        }
        &::after {
            transition-delay: 0.3s;
        }
    }
}
.card-btn>button {
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    // width:145px;
    font-family: var(--semibold);
    padding:6px 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    border:none;
    color: var(--themeColor);
    overflow: hidden;
    z-index: 150;
    &::before, &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -100%;
        left: 0;
        background: var(--themeTextGradient);
        z-index: -1;
        transition: all 400ms ease-in;
    }
    svg{
        [stroke]{
            stroke: var(--themeColor) ;
        }
        [fill]{
            fill: var(--themeColor);
        }
    }
    &:hover{
        color: var(--white);
        outline:transparent;
        z-index: 10000;
        svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
        &::before,&::after{
            top: 0;
        }
        &::before {
            opacity: 0.3;
        }
        &::after {
            transition-delay: 0.3s;
        }
    }
}
.btn-danger >button {
    padding: 6px 20px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 8px;
    background: var(--themeTextGradient);
    cursor: pointer;
    &::before {
        content: "";
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-radius: 7px;
        background-color: white;
        z-index: -1;
        transition: 200ms
    }
    &::after {
        content: attr(data);
        font-size: 16px;
        background: var(--themeTextGradient);
        -webkit-background-clip: text;
        color: transparent;
        transition: 200ms
    }
    &:hover::before {
        opacity: 10%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
    :hover::after {
        color: var(--white);
    }
}

.banner-bg{
    width:100%;
    min-height: 660px;
    height:100vh;
    background: url('../images/png/banner-bg.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size:cover;
}
.slick-dots {
    position: absolute;
    bottom: 40px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left !important;
  }

.card{
    // width:100%;
    background: url('../images/png/card-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 20px;
}
.social-link{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 50px;
    margin: auto;
    display: flex;
    gap: 15px;
    z-index: 10;
    height: 200px;
    flex-wrap: wrap;
    width: 20px;
}

.card-content-wrap{
    position: relative;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.slick-prev, .slick-next {
    width: 30px;
    height: 30px;
  }
.slick-next {
    right: -50px;
  }
.slick-prev {
    left: -40px;
  }

.textarea-box{
 position: relative;
}
.textarea-box> textarea{
    background: var(--bgColor);
    padding: 10px 10px 10px 35px;
    border-radius: 8px;
    outline: none;
    border: none;
    width: 100%;
    color: var(--textGrey) !important;
}
select{
    position: relative;
    width: 100%;
    background: var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    color: var(--textPalceholder);
    padding: 0 15px;
    outline: none;
}
.project-field{
    position: relative;
}
.project-field> svg{
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 1;
    bottom: 0;
    margin: auto;
}
.project-type{
    position: relative;
    color: var(--textPalceholder);
    padding-left: 50px;
}

.textarea-box> textarea::placeholder{
    color: var(--textPalceholder);
}
.text-icon{
    position: absolute;
    top: 10px;
    left: 10px;
}
.toggleBtn{
    display: none;
}

.Footer-social-links{
    display: flex;
    align-items: center;
    gap: 20px;
}
.error-message{
    padding: 4px 6px;
    // margin: 9px 0;
    color: red !important;
    font-size: 13px !important;
}
input{
    color: var(--themeColor) !important;
}
.about-arabic{
    display:none;
}
.SlideBox .gradientGrey{
    width: 47%;
    padding: 0 10px;
}



/********************Custom Slick slider*******************/

.row-slider{
    padding: 0;
}
.slider-nav-wrapper{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 15px 0 40px;
}
.slider-nav-container{
    max-width: 450px;
}

.tesSlideContent{
    position: relative;
    max-width: 98%;
    background-color: var(--white);
    border-radius: 20px;
    padding: 60px 30px;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    filter: drop-shadow(0px 5px 5px rgba(219, 219, 219, 0.85));
    margin: auto;
}

.vector {
    background-image: url(../images/png/Vector.png);    background-repeat: no-repeat;
    position: absolute;
    top: 40px;
    // right: 0px;
    left: 50px;
    width: 100px;
    height: 100px;
  }
.vector2{
    position: absolute;
    bottom: 0;
    right: 50px;
    width: 100px;
    height: 100px;
    background-image: url(../images/png/Vector2.png);
    background-repeat: no-repeat;
}

.testi-nav .slick-track .slick-slide{
    margin-bottom:30px;
}
@mixin triangle($background: white, $radius: true) {
	&,
	&::before,
	&::after {
		width: 30px;
        height: 30px;
	}
	position: relative;
	overflow: hidden;
	transform: translateY(50%) rotate(330deg) 
    skewY(30deg) scaleX(0.866);
	pointer-events: none;

	&::before,
	&::after {
		content: '';
		position: absolute;
		background: $background;
		pointer-events: auto;
	}

	&::before {
		// prettier-ignore
		transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
	}

	&::after {
		// prettier-ignore
		transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
	}

	@if ($radius) {
		border-radius: 25%;

		&::before {
			border-radius: 20% 20% 20% 55%;
		}

		&::after {
			border-radius: 20% 20% 55% 20%;
		}
	}
}
.triangle-3{
    @include triangle( white, true);
	position: absolute;
    bottom: -8px;
    right: 0;
    left:20px;
    margin: auto;
    z-index: 10000;

}
.tesSlideContentCol{
    width: 70%;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center; 
}
.client-img {
    cursor: pointer;
    
}
// .slick-track>div:nth-child(3n-1) .client-img{
//     width: 55px !important;
//     height: 55px !important;
//     opacity: 0.4;
// }

.slider-for .slick-list{
    overflow: visible;
}
.slider-nav-wrapper .slick-slide{
    height: 100px;
    width: 90px !important;
    position: relative;
}

.slider-nav-wrapper .slick-current .client-img{
    width: 85px !important;
    height: 85px !important;
    opacity: 1 !important;
    filter: drop-shadow(0px 4px 5px rgba(202, 202, 202, 0.85));
    // box-shadow: 0px 4px 10px 0px rgba(159, 159, 164, 0.26);
    // -webkit-box-shadow: 0px 4px 10px 0px rgba(159, 159, 164, 0.26);
    // -moz-box-shadow: 0px 4px 10px 0px rgba(159, 159, 164, 0.26);
}

.slider-nav-wrapper .slick-slide .client-img{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}
.slider-nav-wrapper .slick-active .client-img{
    opacity: 0.5 ;
 }
.slick-prev, .slick-next{
    width: 30px !important;
    height: 30px !important;
}
.comparing > .row{
    margin: 0 !important;
}
.footer-revised{
    display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  & span{
    font-size: 12px !important;
  }
}
.footer-revised .themeTextGradient{
    font-size: 12px !important;
    cursor: pointer;
}
.modal-content{
    margin-top: 10%;
}
.model-footer .btn-gradient {
    z-index: 100000;
}
.video-bg  .modal-content {
    // background-color: transparent !important;
    // background: transparent !important;
    border: none !important;
}
.video-bg .modal-header {
    border: none !important;
}
.videocontent{
    width:100% !important;
}
video{
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}
.title-card{
    width: auto !important;
    padding: 0 !important;
     margin: 0 !important;
     background: none !important; 
     border-radius: 0 !important; 
     box-shadow: none !important;
    will-change: transform;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
}
.css-13cymwt-control{
    height: 43px !important;
    border: transparent !important;
    background: var(--bgColor) !important;
    border-radius: 8px !important;
}
.css-1u9des2-indicatorSeparator{
    display: none !important;
}
.service-card-content span img{
    position: relative;
    right: 10px;
  }

 .project-digital img{
    position: relative;
    right: 10px;
  }
  .css-b62m3t-container {
    z-index: 70 !important;
  }
  .arabic-logo{
    display: none;
  }
//   .arabic-footer-logo{
//     display: none;
//   }



/***************** RTL DIrection*************************/
.rtl .arabic-logo{
    display: block;
    width: 180px;
  }
  .rtl .logo{
    display: none;
  }
// .rtl .arabic-footer-logo{
//     display: block;
//   }

// .rtl .footer-logo{
//     display: none;
//   }
.rtl .nav-bar-list a,
.rtl .footer-list a{
    font-family: var(--arSemiBold) !important;
}
.rtl .tabNavBtn{
    font-family: var(--arBold);
}
.rtl input::placeholder,
.rtl .css-1jqq78o-placeholder{
    font-family: var(--arMedium) !important;
    color: var(--textPalceholder);
}
.rtl textarea::placeholder{
    font-family: var(--arMedium);
}
.rtl .banner-card .sub-heading{
    line-height: 55px !important;
}
.rtl .banner-card .heading {
    line-height: 75px !important;
  }
.rtl .banner-card .TextGradientFont{
    line-height: 70px !important;
    letter-spacing: 0;
    font-family: var(--arLight) !important;
}
.rtl .about-content-wrap .subHeading{
    line-height: 30px !important;
}
.rtl .about-content-wrap .heading-text-response{
    line-height: 1.3 !important;
}

.rtl .about-card-img {
    right: auto !important;
    left: 0;
}
.rtl svg {
    right: 0;
    left: auto;
}

.rtl .text-icon{
    left: auto;
}

.rtl [dir='ltr'] .slick-slide {
    float: left !important;
    direction: rtl !important;
}
.rtl .location-card p{
    direction: ltr !important;
}
.rtl .nav-bar button >span{
    direction: ltr !important;
}
.rtl .logo svg {
    transform: scale(1);
}

.rtl .location-card p{
    text-align: right;
}

.rtl .footer-vector-img {
    width: 180px !important;
}

.rtl input{
    padding-right: 35px;
    padding-left: 10px;
}

.rtl .textarea-box> textarea{
    padding: 10px 35px 10px 10px;
}

.rtl .whatsapp {
    position: fixed;
    bottom: 15px;
    right: 10px !important;
    left: auto !important;
    z-index: 1;
}
.rtl .change-Btn {
    position: fixed;
    bottom: 0;
    right: auto !important;
    left: 0 !important;
}
.rtl .social-link{
    right: auto;
    left: 50px;
}
.rtl .popup {
    display: none;
}
.rtl .arabic-popup{
    display: block !important;
    position: absolute !important;
    z-index: 10 !important;
}
.rtl .arabic-pop-mobile {
    top: 90px;
    left: auto;
    right: 0;
}
.rtl .arabic-pop-social {
    top: 70px;
    bottom: 0;
    left: -10px;
    right: auto;
    margin: auto;
}
.rtl .arabic-pop-website {
    bottom: 70px;
    right: -60px;
    left: auto;
  }
.rtl .service-card >span {
    top: 0;
    right: auto !important;
    left: 0;
  }
  .rtl .project-digital {
    background-position: top 0 left 10%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px  !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }
  .rtl .project-other {
    background-position: top 0 left 10%;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px  !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .rtl .project-digital::after {
    box-shadow: -20px 0 20px -20px rgba(88, 108, 154, 0.5) inset !important;
    right: auto !important;
    left: -5px;
  }
  .rtl .slick-prev {
    right: -40px !important;
    left: auto;
    top: 0 !important;
    bottom: 0 !important;
    margin: auto;
  }
  .rtl .slick-next {
    right: auto;
    left: -40px !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: auto;
  }
 .rtl .slick-dots {
    text-align: right !important;
  }
  .rtl .bannerImg {
    text-align: center !important;
  }
  .rtl .location-card svg,
  .rtl .input-svg,
  .rtl .project-field svg,
  .rtl .text-icon svg{
    margin: 0 8px !important;
  }
  .rtl .project-field > svg {
    position: absolute;
    top: 10px;
    }

    .rtl select option{
        font-family: var(--arMedium) !important;
      }
  .rtl .project-type {
    position: relative;
    color: var(--textPalceholder);
    padding-left: auto !important;
    padding-right: 35px;
    font-family: var(--arMedium) !important;
  }
  
  .rtl .project-bg{
    right: auto !important;
    left: 6%;
  }

.rtl .SlideBox img,
// .rtl .about-card-img,
.rtl svg ,
.rtl .text-icon,
.rtl .about-bg .custom-img,
.rtl .service-card >span,
.rtl .project-bg,
.rtl #contact .btn-gradient-bg svg{
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
}
.rtl .footer-social svg,
.rtl .social-link svg,
.rtl .btn-gradient svg,
.rtl .btn-gradient-bg svg,
.rtl .card-btn svg,
.rtl .logo svg{
    -webkit-transform: scaleX(1) !important;
    -moz-transform: scaleX(1) !important;
    -o-transform: scaleX(1) !important;
    transform: scaleX(1) !important;
}
.rtl .ReactSelectFlags-module_selectWrapper__I7wcI .ReactSelectFlags-module_clearIcon__EdJlf {
    width: 15px !important;
    height: 15px !important;
    transform: translateY(0%) !important;
    right: auto !important;
    left: 30px;
}
.rtl .ReactSelectFlags-module_container__SFuNT .ReactSelectFlags-module_selectWrapper__I7wcI .ReactSelectFlags-module_closeIcon__t4cEW {
    
    right: auto !important;
    left: 8px;
}
.rtl .service-card-content span img{
    position: relative;
    left: 10px;
    right: auto;
  }
.rtl .project-digital img{
    position: relative;
    left: 10px;
    right: auto;
  }
  .rtl .rocket-animation{
    left: 0px !important;
    right: auto !important;
}


/***************** responsive file*************************/


@import 'responsive';
 